import React from 'react';
import styles from './LogoBlock.module.scss';
import logo from '../../images/logo_primary.png';

export const LogoBlock = () => {
    return (
        <div className={`${styles.logoBlockContainer} row`}>
            <div className='col-12 d-flex justify-content-center'>
                <img src={logo} alt='maximum software solutions eloquent looking logo' className={styles.logo}/>
            </div>
        </div>

    )
}