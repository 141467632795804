import React from 'react';

export const AboutBlock = () => {
  return (
    <div className="mt-2">
      <div className="row mb-4">
        <div className="col-12 mb-1">
          <h2>About</h2>
        </div>
        <div className="col-12">
          <p>We are a team of professional software engineers.</p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12 mb-1">
          <h2>Services</h2>
        </div>
        <div className="col-12">
          <p>
            We specialize in design and creation of websites, desktop applications, and mobile
            applications to help make your life easier and your systems more lean. Our forte is
            working on software to improve your logistics as a business, your marketing to
            customers, and the overall user experience for your end users. Our primary means to
            software are web-based stacks which include wordpress, drupal, next.js, react,
            react-native, node.js, mongoDb, and mySQL.
          </p>
        </div>
      </div>
      <div className="row" id='form'>
        <div className="col-12 mb-1">
        <h2>Pricing</h2>
      </div>
      <div className="col-12">
        <p>Project Pricing & Hourly Pricing starting at 65.00/hr</p>
      </div>
          </div>
          </div>
  );
};
