import React from 'react'; 
import styles from './PageFooter.module.scss';

export const PageFooter = () => {
    return (
        <div className={`${styles.pageFooter} row mt-5`}>
            <div className={`col-12 ${styles.text}`}>
                <p>Copyright 2022 Maximum Software Solutions</p>
            </div>
            
        </div>
    )
}