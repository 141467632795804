import React from 'react';
import styles from './Map.module.scss';
import GoogleMapReact from 'google-map-react';
import MarkerImage from '../../../images/map_marker.png';

function createMapOptions(maps) {
  return {
    disableDefaultUI: true,
    draggable: false,
    panControl: false,
  };
}

export const Map = () => {
  const center = { lat: 44.73128510629321, lng: -85.62706607627197 };
  const zoom = 16;

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '300px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
        defaultCenter={center}
        defaultZoom={zoom}
        options={createMapOptions}>
      
        
        <Marker lat={44.73128510629321} lng={-85.62706607627197} text="Maximum Software Solutions, LLC" />
      </GoogleMapReact>
    </div>
  );
};

const Marker = () => {
  return <img className={styles.marker} src={MarkerImage} alt='location marker for 3055 Cass Road, Traverse City Michigan'/>
};
