import React from 'react';
import styles from './MobileHeader.module.scss';

export const MobileHeader = () => {
    return (
        <div className={`container-fluid ${styles.headerContainer}`}>
            <div className={`row d-flex justify-content-between ${styles.headerRow}`}>
                <div className={`col ${styles.hours}`}>
                    10am - 5pm ET M-F
                </div>
                <div className={`col ${styles.phone}`}>
                    <a href='tel:2313338040'>(231) 333-8040</a>
                </div>
            </div>
        </div>
    )
}