import React from 'react';
import styles from './DesktopHeader.module.scss';

export const DesktopHeader = () => {
    return (
        <div className={styles.desktopHeader}>
            <hr></hr>
            <div className="row d-flex justify-content-center">
                <div className={`col-sm-12 col-md-10 col-lg-8 col-xl-6' ${styles.itemContainer}`}>
                <div><a href='tel:2313338040'>(231) 333-8040</a></div>
                <div>10am - 5pm M-F</div>
                <div>3055 Cass Rd, Traverse City, MI 49684 Suite 206</div>
                </div>
            </div>
        </div>
    )
}