import React from 'react';
import styles from './MobileFooter.module.scss';

export const MobileFooter = () => {
  return (
    <a href="#form">
      <div className={`container-fluid ${styles.footerContainer}`}>
        <div className={`row d-flex justify-content-between ${styles.footerRow}`}>
          <div className={`col ${styles.text}`}>Request Free Consultation</div>
        </div>
      </div>
    </a>
  );
};