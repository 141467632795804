import React from 'react';
import styles from './MapBlock.module.scss';
import { Map } from './Map/Map';

export const MapBlock = () => {
  return (
    <div className={styles.mapBlock}>
      <div className="row mt-5">
        <div className="col-12">
          <h2>Located In Traverse City, MI</h2>
        </div>
      </div>
      <div className="row mt-4">
        <div classname="col-12">
          <Map />
        </div>
      </div>
    </div>
  );
};
