import React, { useState } from 'react';
import styles from './FormBlock.module.scss';
import axios from 'axios';
import { AsYouType } from 'libphonenumber-js';

export const FormBlock = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [notes, setNotes] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);

  const handlePhoneChange = (e) => {
    setPhone(new AsYouType('US').input(e.currentTarget.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //handle submit here
    console.log('Submit Called: ', name, email, phone, company, notes);
    setShowLoader(true);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API}/request`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        name: name,
        email: email,
        phone: phone,
        company: company,
        notes: notes,
      },
    })
      .then(function (res) {
        console.log(res);
        setShowLoader(false);
        if (res.status === 200) {
          setIsSubmitted(true);
        }
      })
      .catch((e) => {
        // if (e.response.status === 500) {
          setIsSubmitError(true);
        // }
        console.log('e', e);
        setShowLoader(false);
      });
  };

  return (
    <div className={`${styles.formBlock} mt-5`}>
      <div className="row mb-4">
        <div className="col-12 mb-1">
          <h2 className={styles.header}>Request a Free Consultation</h2>
        </div>
        <div className="col-12">
          <p>Lets Talk Software Solutions</p>
        </div>
      </div>
      <div className="row">
        {!isSubmitted && !isSubmitError ?
          <form onSubmit={handleSubmit} className={styles.formItems}>
            {/* mobile */}
            <div className={styles.mobileFormItems}>
              <div className="col-12 mb-2">
                <input
                  className={styles.input}
                  required
                  placeholder="Name*"
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 mb-2">
                <input
                  className={styles.input}
                  required
                  placeholder="Email*"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 mb-2">
                <input
                  className={styles.input}
                  required
                  placeholder="Phone*"
                  type="text"
                  value={phone}
                  onChange={handlePhoneChange}
                />
              </div>
              <div className="col-12 mb-2">
                <input
                  className={styles.input}
                  required
                  placeholder="Company*"
                  type="text"
                  value={company}
                  onChange={(e) => {
                    setCompany(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 mb-2">
                <textarea
                  className={styles.area}
                  placeholder="Notes"
                  type="text"
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                />
              </div>
              <div className="col-12 mb-2">
                <button className={styles.submitButton} type="submit" value="Submit">
                  {!showLoader && (
                    <div className={styles.mobileSubmitTextContainer}>Submit Request</div>
                  )}
                  {showLoader && <div className={styles.loader}></div>}
                </button>
              </div>
            </div>
            {/*Desktop*/}
            <div className={styles.desktopFormItems}>
              <input
                className={`${styles.inputDesktop} ${styles.name}`}
                required
                placeholder="Name*"
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />

              <input
                className={`${styles.inputDesktop} ${styles.email}`}
                required
                placeholder="Email*"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />

              <input
                className={`${styles.inputDesktop} ${styles.phone}`}
                required
                placeholder="Phone*"
                type="text"
                value={phone}
                onChange={handlePhoneChange}
              />

              <input
                className={`${styles.inputDesktop} ${styles.company}`}
                required
                placeholder="Company*"
                type="text"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
              />

              <textarea
                className={styles.areaDesktop}
                placeholder="Notes"
                type="text"
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
              />

              <button className={styles.submitButton} type="submit" value="Submit">
                Submit Request
              </button>
              {showLoader && (
                <div className={styles.desktopLoaderContainer}>
                  <div className={styles.loader}></div>
                </div>
              )}
            </div>
          </form>
        : ''}
        {isSubmitted &&
          <div className='col'>
            <div className={styles.submittedContainer}>
            <span className={styles.submittedHeading}>Thank you for your submission!</span><br/>
              <span className={styles.subbmittedSubText}>We will be in touch within the next business day.</span>
              </div>
        </div>
        }
        {isSubmitError &&
          <div className='col'>
            <div className={styles.submittedContainer}>
            <span className={styles.submittedHeading}>Sorry, there seems to be an issue with our backend!</span><br/>
              <span className={styles.subbmittedSubText}>Please give us a call at (231) 333-8040</span>
              </div>
        </div>
        }
      </div>
    </div>
  );
};
