import './styles/App.scss';
import { MobileHeader } from './components/MobileHeader/MobileHeader';
import { LogoBlock } from './components/LogoBlock/LogoBlock';
import { AboutBlock } from './components/AboutBlock/AboutBlock';
import { MobileFooter } from './components/MobileFooter/MobileFooter';
import { FormBlock } from './components/FormBlock/FormBlock';
import { MapBlock } from './components/MapBlock/MapBlock';
import { PageFooter } from './components/PageFooter/PageFooter';
import { DesktopHeader } from './components/DesktopHeader/DesktopHeader';

function App() {
  return (
    <div className="App">
      <header>
        <MobileHeader />
        <div className="container extraPadding">
          <LogoBlock />
          <DesktopHeader/>
        </div>
      </header>
      <div className="container extraPadding">
        <AboutBlock />
        <FormBlock />
        <MapBlock />
      </div>
      <footer>
        <div className="container">
          <PageFooter />
          </div>
        <MobileFooter />
      </footer>
    </div>
  );
}

export default App;
